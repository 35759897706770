module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/opt/buildhome/repo/src/components/Layout/Markdown.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"candu-docs","short_name":"docs","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"/opt/buildhome/repo/src/images/favicon-32x32.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3d6414ce5fa3bf402ed1c3d823fb8a52"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://7fa02014d76b4988bb6eda22ca077b2b@o238672.ingest.sentry.io/5280351","environment":"production","enabled":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"qSaqXZ7mWGRyrd1yWUPBg6CDL5hFAlTJ","devKey":"C1fRZ6rv3QRvgdaMZskxt8PWjKzh5Hmg","trackPage":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
